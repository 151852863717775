//require('./bootstrap');
require('./navbar');

/*
    No long needed due to Google forcing their CMP on us

// Functions
function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

// Show cookie preferences if it doesnt exist
var cookiePreferences = getCookie('cookiePreferences');
if(!cookiePreferences) {
    document.getElementById('cookiePopup').classList.add('show');
} else {
    // Turn off personalised ads if declined
    if(cookiePreferences == 'no') {
        (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1;
    }
    // Resume ads
    (adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0;
}

// Cookie pop up - Handle 'No consent'
document.getElementById('cookieBtnReject').addEventListener("click", function() {
    setCookie('cookiePreferences', 'no', 365);
    (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1;
    (adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0;
    document.getElementById('cookiePopup').style.display = "none";
});

// Cookie pop up - Handle 'Consent'
document.getElementById('cookieBtnConsent').addEventListener("click", function() {
    setCookie('cookiePreferences', 'yes', 365);
    (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=0;
    (adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0;
    document.getElementById('cookiePopup').style.display = "none";
});
*/
